import React, { useContext } from 'react'
import AccordionContext from 'react-bootstrap/AccordionContext'
import Accordion from 'react-bootstrap/Accordion'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import Button from '../../components/Button'
import clsx from "clsx";
import {
	accordion,
	accordionFirst,
	accordionItem,
	accordionHeader,
	accordionContent,
	ctaWrapper,
}  from './Accordion.module.scss'

const ContextAwareToggle = ({ children, eventKey, callback }) => {
	const { activeEventKey } = useContext(AccordionContext);


	const decoratedOnClick = useAccordionButton(
		eventKey,
		() => callback && callback(eventKey)
	)

	const isCurrentEventKey = activeEventKey === eventKey;

	return (
		<button
			type="button"
			onClick={decoratedOnClick}
			aria-expanded={isCurrentEventKey}
		>
			{children}
			{!isCurrentEventKey ? (
				<i className="ss-icon ss-black-tie-regular ss-plus" />
			) : (
				<i className="ss-icon ss-black-tie-regular ss-hyphen" />
			)}
		</button>
	)
}

const ProAccordion = ({
	title,
	content,
	index,
	first,
	ctaText,
	ctaLink,
	mailto,
}) => {

	return (
		<>
			<Accordion key={index} className={clsx(accordion, first ? accordionFirst : '')}>
				<div className={accordionItem}>
					<h2 className={accordionHeader}>
						<ContextAwareToggle eventKey={index}>{title}</ContextAwareToggle>
					</h2>
					<Accordion.Collapse eventKey={index}>
						<>
							<div
								dangerouslySetInnerHTML={{ __html: content }}
								className={clsx('editor-section', accordionContent)}
							/>

							{/* Optional accordion content CTA */}
							{ctaLink && (
								<span className={ctaWrapper}>
									<Button buttonText={ctaText} href={ctaLink} size={'small'} />
								</span>
							)}
						</>
					</Accordion.Collapse>
				</div>
			</Accordion>
		</>
	)
}
export default ProAccordion
